export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return phoneNumber;

  let phoneStr = phoneNumber.replace(/\D/g, '');

  // Remove country code (only handles US)
  if (phoneStr.length === 11 && phoneStr[0] === '1') {
    phoneStr = phoneStr.slice(1);
  }

  if (phoneStr.length !== 10) {
    throw new Error('Invalid phone number length');
  }

  return `(${phoneStr.slice(0, 3)}) ${phoneStr.slice(3, 6)}-${phoneStr.slice(6)}`;
};
